import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const CompanyPage = () => (
  <Layout>
    <SEO title="Company" />
  </Layout>
)

export default CompanyPage
